import type { AxiosInstance } from 'axios'
import type { App as AppType, Directive, InjectionKey } from 'vue'
import type { RouteLocation } from 'vue-router'
import { AxiosError } from 'axios'

let App: AppType | null = null

/**
 * visible in GlobalProperties
 */
const utilsGlobalProperties = {
  getAppRefer: () => {
    return App
  },
  getAppGlobalProperties: () => {
    return App?.config?.globalProperties || null
  }
}

/**
 * visible in appUtilsPlugin (imported globally by AutoImport package)
 */
function nextLoopEvent(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback)
  if (!resolved) {
    throw new Error(`Could not resolve ${key.description}`)
  }
  return resolved
}

function getFullRouteData(route: VueRouteLocation, silent = false): undefined | RouteLocation {
  try {
    const globalProperties = utilsGlobalProperties.getAppGlobalProperties()
    if (!globalProperties) {
      return undefined
    }
    return typeof route === 'string'
      ? globalProperties.$router.resolve({ name: route })
      : globalProperties.$router.resolve(route)
  } catch (e) {
    if (!silent) {
      console?.error?.(e, route)
    }
  }
}

function isNotNullGuard<T>(data: T | null): data is T {
  return (data as T) !== null
}

function getAxiosInstance(): AxiosInstance | null {
  return utilsGlobalProperties.getAppGlobalProperties()?.$axios || null
}

function catchError(optionsNotify = {} as Record<string, any>) {
  return (error: Error | boolean | AxiosError) => {
    if (error instanceof AxiosError) {
      let customClass = 'child-inherit-colors text-red-600 z-[999999] right-1/2 translate-x-1/2 large'
      if (optionsNotify.appendTo) {
        customClass += ' absolute'
      }
      utilsGlobalProperties.getAppGlobalProperties()?.$notify({
        title: 'Error',
        type: 'warning',
        customClass,
        message: `${error?.response?.data?.message || error?.response?.data?.errors?.[0] || error}`,
        duration: 9000,
        offset: 100,
        ...(optionsNotify ?? {})
      })
    } else if (error instanceof Error) {
      console?.error?.(error)
    }
  }
}

function catchFormErrors(externalErrors: Record<string, any>, optionsNotify = {} as Record<string, any>) {
  return (error: Error | boolean | AxiosError) => {
    if (error instanceof AxiosError &&
      error?.response?.status &&
      error?.response?.status < 401 &&
      error.response?.data?.status === 'error' &&
      error.response?.data?.errors
    ) {
      let isMatchedFieldError = false
      let errorPopupMsg = ''
      const fieldErrors = error.response.data.errors
      Object.entries(fieldErrors).forEach(([fieldName, errorValue]) => {
        if (fieldName in externalErrors) {
          isMatchedFieldError = true
          externalErrors[fieldName] = errorValue
          delete fieldErrors[fieldName]
        }
      })
      errorPopupMsg = Object.entries(fieldErrors)
        .map(([, val]) => {
          return val
        })
        .join(', ')
      if (isMatchedFieldError) {
        errorPopupMsg = `Not all fields are valid! <br/>${errorPopupMsg}`
      }
      if (errorPopupMsg) {
        let customClass = 'child-inherit-colors text-red-600 z-[999999] right-1/2 translate-x-1/2 large'
        if (optionsNotify.appendTo) {
          customClass += ' absolute'
        }
        utilsGlobalProperties.getAppGlobalProperties()?.$notify({
          title: 'Error',
          type: 'warning',
          customClass,
          message: `${errorPopupMsg || ''}`,
          duration: 9000,
          offset: 100,
          ...(optionsNotify ?? {})
        })
      }
    } else {
      catchError(optionsNotify)(error)
    }
  }
}

const appUtilsPlugin = {
  ...utilsGlobalProperties,
  nextLoopEvent,
  injectStrict,
  getFullRouteData,
  isNotNullGuard,
  getAxiosInstance,
  catchError,
  catchFormErrors
}

const focusDirective: Directive = {
  mounted(el) {
    const input = el?.querySelector('input')
    if (input) {
      input?.focus()
    }
  }
}

/**
 * register  utilsGlobalProperties as $utils
 */
export default {
  install: (Vue: AppType) => {
    Vue.config.globalProperties.$utils = utilsGlobalProperties
    App = Vue
    Vue.directive('focus', focusDirective)
  }
}
/**
 * export appUtilsPlugin (imported globally by AutoImport package)
 * rest of exports require manual import
 */
export { appUtilsPlugin }
