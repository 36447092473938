import type { App } from 'vue'
import axios from 'axios'

export default {
  install: (Vue: App) => {
    const instance = axios.create()
    const auth = useAuthStore()

    instance.interceptors.request.use((config) => {
      config.headers.Authorization = auth.getToken
      return config
    })

    instance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error?.response?.status === 401) {
          auth.logout()
          return false
        } else if (error?.response?.status > 400) {
          Vue.config.globalProperties.$notify({
            title: 'Error',
            type: 'warning',
            customClass: 'child-inherit-colors text-red-600 z-[999999]',
            message: error?.response?.data?.msg || 'Oops something went wrong',
            duration: 9000
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(false)
        }
        return Promise.reject(error)
      }
    )
    Vue.config.globalProperties.$axios = instance
  }
}
