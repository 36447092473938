import type { PageBasicData, PageMetaData, RoutesData } from '@/router/init/siteRouter.types'
import type { App } from 'vue'
import { loadingPage } from '@/components/fbee/layout/page/Loader'
import { siteDefaultSeoSettings } from '@/settings/seo'
import { useHead } from '@vueuse/head'
import {
  createRouter,
  createWebHistory,
  type RouteLocation,
  type RouteLocationNormalized,
  type RouteRecordRaw
} from 'vue-router'

import pagesRoutesConfigurator from '../routes'

const MainLayout = () => import('@/layouts/MainLayout.vue')

/**
 * @field creates template strings, in which we place variables: {nameVariable}, whose values are passed in the data parameter (the value of the data object field of the same name)
 * @param template string
 * @param data object with values for variables of template string
 */
export function replaceTemplate(template: string, data: Record<string, unknown> | undefined) {
  if (data) {
    const patternVariables = /\{\s*(\w+)\s*\}/g // eg {property_name}
    return template.replace(patternVariables, (_, token) => String(data[token] || `{${token}}`))
  }
  return template
}

export function isString(value: unknown): value is string {
  return typeof value === 'string'
}

const reactiveHeadData = {
  title: ref(''),
  description: ref(''),
  image: ref(''),
  path: ref(''),
  bodyClass: ref(''),
  isInit: false
}

export function initReactivePageData() {
  useHead({
    title: reactiveHeadData.title,
    meta: [
      {
        property: 'og:title',
        content: reactiveHeadData.title
      },
      {
        name: 'description',
        content: reactiveHeadData.description
      },
      {
        property: 'og:description',
        content: reactiveHeadData.description
      },
      {
        property: 'og:url',
        content: reactiveHeadData.path
      },
      {
        property: 'og:image',
        content: reactiveHeadData.image
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: reactiveHeadData.path
      }
    ],
    bodyAttrs: {
      class: reactiveHeadData.bodyClass
    }
  })
}
export function setBasicPageData(data: PageBasicData, variables: Record<string, unknown> | undefined = undefined) {
  if (isString(data.title)) {
    reactiveHeadData.title.value = replaceTemplate(data.title, variables)
  }
  if (isString(data.description)) {
    reactiveHeadData.description.value = replaceTemplate(data.description, variables)
  }
  if (isString(data.path)) {
    reactiveHeadData.path.value = `${import.meta.env.VITE_HOST || ''}${data.path}`
  }
  if (isString(data.image)) {
    reactiveHeadData.image.value = `${import.meta.env.VITE_HOST || ''}${data.image}`
  }
  if (isString(data.bodyClass)) {
    reactiveHeadData.bodyClass.value = data.bodyClass
  }
}

let vue: App
function guardBeforeEnter(to: RouteLocation) {
  if (to) {
    return true
  }
  return true
}
function getSkeletonRoutes(): RouteRecordRaw[] {
  return [
    { path: '/:pathMatch(.*)*', name: '404', component: () => import('@/pages/404/ErrorPage.vue'), beforeEnter: [guardBeforeEnter] },
    {
      path: '/',
      name: 'home',
      component: MainLayout,
      beforeEnter: [guardBeforeEnter],
      children: []
    }
  ]
}

function routerSetBasicPageDataCallBack(to: RouteLocationNormalized) {
  const data: PageMetaData = to
  if (!reactiveHeadData.isInit) {
    initReactivePageData()
    reactiveHeadData.isInit = true
  }
  const resetData: PageBasicData = {
    title: siteDefaultSeoSettings.title || '',
    description: siteDefaultSeoSettings.description || '',
    image: siteDefaultSeoSettings.image || '',
    path: data.meta?.basicSeo?.path || to.path || '',
    bodyClass: siteDefaultSeoSettings.bodyClass || ''
  }
  setBasicPageData({ ...resetData, ...data.meta.basicSeo }, { ...data.meta?.basicSeo?.defaultVariables, ...to.query, ...to.params })
}

export function routerSsgSetBasicPageDataCallBack(to: RouteLocationNormalized) {
  const data: PageMetaData = to
  useHead({
    title: replaceTemplate(data.meta.basicSeo?.title ?? '', data.meta.basicSeo?.defaultVariables ?? {}),
    meta: [
      {
        property: 'og:title',
        content: replaceTemplate(data.meta.basicSeo?.title ?? '', data.meta.basicSeo?.defaultVariables ?? {})
      },
      {
        name: 'description',
        content: replaceTemplate(data.meta.basicSeo?.description ?? '', data.meta.basicSeo?.defaultVariables ?? {})
      },
      {
        property: 'og:description',
        content: replaceTemplate(data.meta.basicSeo?.description ?? '', data.meta.basicSeo?.defaultVariables ?? {})
      },
      {
        property: 'og:url',
        content: `${import.meta.env.VITE_HOST || ''}${to.path}`
      },
      {
        property: 'og:image',
        content: data.meta.basicSeo?.image ? `${import.meta.env.VITE_HOST || ''}${data.meta.basicSeo.image}` : ''
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: `${import.meta.env.VITE_HOST || ''}${to.path}`
      }
    ],
    bodyAttrs: {
      class: data.meta.bodyClass ?? ''
    }
  })
}

function initRouter() {
  const skeletonRoutes = getSkeletonRoutes()
  skeletonRoutes[1].children = pagesRoutesConfigurator.getSiteRoutesReader().getList().map((routeConfig) => {
    return routeConfig.route
  })

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: skeletonRoutes,
    scrollBehavior(to) {
      if (to.hash) {
        return false
      }
      return { top: 0, behavior: 'smooth' }
    }
  })

  /**
   *  start loaderPage
   */
  router.beforeEach((to, from) => {
    if (from.path && to.path !== from.path) {
      loadingPage.value = true
    }
  })

  /**
   * Register seo, end finish loaderPage
   */
  router.afterEach((to) => {
    loadingPage.value = false
    routerSetBasicPageDataCallBack(to)
  })
  /**
   * Register deploy-version checker on router error when SSG;
   * (preventing failures due to the implementation of a new version)
   */
  router.onError((_, to) => {
    const isSsr = import.meta.env.SSR
    const isDevMode = import.meta.env.MODE === 'development'
    if (!isSsr && !isDevMode) {
      const deployVersion = localStorage.getItem('deploy-version')
      fetch('/build.txt')
        .then((response) => {
          if (response.ok) {
            return response.text()
          }
        })
        .then((buildTxt) => {
          if (buildTxt && buildTxt !== deployVersion) {
            localStorage?.setItem?.('deploy-version', buildTxt)
            if (to?.fullPath && window?.location) {
              window.location.href = to.fullPath
            } else {
              location?.reload?.()
            }
          }
        })
    }
  })
  vue.use(router)
}

export function initSsgRoutes() {
  const skeletonRoutes = [getSkeletonRoutes()[1]]
  skeletonRoutes[0].children = pagesRoutesConfigurator.getSiteRoutesReader().getList(false).flatMap((routeConfig) => {
    if (routeConfig.ssg) {
      const ssgRoutes: RoutesData[] = [routeConfig.route]
      for (const ssgRoute of routeConfig.ssg) {
        const ssgRouteData = { ...routeConfig.route }
        ssgRouteData.meta = structuredClone(ssgRouteData.meta)
        if (ssgRouteData.meta.basicSeo) {
          ssgRouteData.meta.basicSeo.image = ssgRoute.image ?? ssgRouteData.meta.basicSeo.image ?? ''
          ssgRouteData.meta.basicSeo.defaultVariables = { ...(ssgRouteData.meta.basicSeo.defaultVariables ?? {}), ...(ssgRoute.defaultVariables ?? {}) }
        }
        ssgRouteData.name = replaceTemplate(ssgRoute.name ?? '', ssgRouteData.meta?.basicSeo?.defaultVariables ?? {})
        ssgRouteData.path = replaceTemplate(ssgRoute.path ?? '', ssgRouteData.meta?.basicSeo?.defaultVariables ?? {})
        ssgRoutes.push(ssgRouteData)
      }
      return ssgRoutes
    }

    return routeConfig.route
  })
  return skeletonRoutes
}
export default {
  install: (app: App) => {
    vue = app
    initRouter()
  }
}
