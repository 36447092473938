import routerInitPlugin from '@/router/init/routerInitPlugin'
import { createHead } from '@vueuse/head'
import { createApp } from 'vue'
import App from './App.vue'
import appUtilsPlugin from './plugins/appUtilsPlugin'
import axiosPlugin from './plugins/axiosPlugin'
import elPlusPlugin from './plugins/elPlusPlugin'
import globalComponents from './plugins/globalComponents'
import piniaPlugin from './plugins/piniaPlugin'
import './css/index.css'

const head = createHead()
const Vue = createApp(App)
Vue.use(routerInitPlugin)
Vue.use(head)
Vue.use(piniaPlugin)
Vue.use(axiosPlugin)
Vue.use(elPlusPlugin)
Vue.use(appUtilsPlugin)
Vue.use(globalComponents)
Vue.mount('#app')
