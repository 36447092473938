import type { SiteRoutesConfigurator } from '@/router/init/SiteRoutesConfigurator'
import { SiteRoutesGroups } from '@/router/init/SiteRoutesGroups'

export class SiteRotesReader {
  private static _list: SiteRoutesConfigurator[] = []
  private static _entityMap: Record<string, number> = {}
  private static _namesMap: Record<string, number> = {}

  private static setCache() {
    SiteRotesReader._list = []
    const groups = SiteRoutesGroups.groups || []
    groups.forEach((group) => {
      group.routes.forEach((route) => {
        SiteRotesReader._list.push(route)
      })
    })
  }

  static getList(fromCache = true) {
    if (!SiteRotesReader._list.length || !fromCache) {
      SiteRotesReader.setCache()
    }
    return this._list
  }

  static getEntityMap(fromCache = true) {
    if (!SiteRotesReader._entityMap.length || !fromCache) {
      SiteRotesReader.setCache()
    }
    return this._entityMap
  }

  static getNamesMap(fromCache = true) {
    if (!SiteRotesReader._namesMap.length || !fromCache) {
      SiteRotesReader.setCache()
    }
    return this._namesMap
  }
}
