import IndexPage from '@/pages/index/IndexPage.vue'
import { SiteRoutesConfigurator } from '@/router/init/SiteRoutesConfigurator'

SiteRoutesConfigurator.registerRoute(
  SiteRoutesConfigurator
    .BuildRoute('dashboard', '/', IndexPage)
    .setPageTitle('Dashboard')
)

export default SiteRoutesConfigurator
