import {
  type BeforeInstallPromptEvent,
  canInstallAsApp,
  deferredPrompt,
  supportedInstallAsApp
} from '@/components/fbee/layout/install/Prompt'
import { isFirefox, isOpera } from 'mobile-device-detect'

const { isProd } = siteUtils // eg in opera BeforeInstallPromptEvent: prompt() method exist but not works;
let isTestMode = false
if (!isProd && !import.meta.env.SSR && window?.location?.search === '?testModeInstallPwa') {
  //  /game/soccer-clash?testModeInstallPwa
  isTestMode = true
}

if (!import.meta.env.SSR && window?.BeforeInstallPromptEvent && ((!isOpera && !isFirefox) || isTestMode)) {
  supportedInstallAsApp.value = true
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault()
    deferredPrompt.value = e as BeforeInstallPromptEvent
    canInstallAsApp.value = true
  })
}
