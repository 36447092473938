import { SiteRoutesGroup } from '@/router/init/SiteRoutesGroup'

export class SiteRoutesGroups {
  static groups: SiteRoutesGroup[] = []

  static addGroup(groupId: string, label = '', icon = '') {
    const group = new SiteRoutesGroup(groupId, label, icon)
    SiteRoutesGroups.groups.push(group)
    return group
  }
}
