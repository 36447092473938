import { siteUtils } from '@/compositions/siteUtils'
import { defineStore } from 'pinia'

export interface AuthState {
  token?: string
  email?: string
  id?: string
}
const useAuthStore = defineStore('storeAuth', () => {
  const auth = ref<AuthState>({})
  const getToken = computed(() => auth.value?.token || '')
  const getEmail = computed(() => auth.value?.email || '')
  const isLogged = computed(() => {
    return !!auth.value?.token
  })
  function login(authToken: string, email: string) {
    auth.value = {
      token: authToken,
      email
    }
  }
  function logout() {
    const { getAxiosInstance } = appUtilsPlugin
    const { getFluxabeeApiUrl } = siteUtils
    getAxiosInstance()?.get(getFluxabeeApiUrl('logout')).catch((err: Error) => {
      // eslint-disable-next-line no-console
      console?.log(err)
    })
    auth.value = {}
  }
  return { auth, getToken, getEmail, login, logout, isLogged }
}, { persist: !import.meta.env.SSR })

export { useAuthStore }
